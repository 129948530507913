*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: Roboto, -apple-system, 'Helvetica Neue', 'Segoe UI', Arial, sans-serif;
	background: #3b4465;
}

.forms-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  height : 100vh;
}

.section-title {
	font-size: 32px;
	letter-spacing: 1px;
	color: #fff;
}

.forms {
	display: flex;
	align-items: flex-start;
	margin-top: 30px;
}

.form-wrapper {
	animation: hideLayer .3s ease-out forwards;
}

.form-wrapper.is-active {
	animation: showLayer .3s ease-in forwards;
}

@keyframes showLayer {
	50% {
		z-index: 1;
	}
	100% {
		z-index: 1;
	}
}

@keyframes hideLayer {
	0% {
		z-index: 1;
	}
	49.999% {
		z-index: 1;
	}
}

.switcher {
	position: relative;
	cursor: pointer;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding: 0;
	text-transform: uppercase;
	font-family: inherit;
	font-size: 16px;
	letter-spacing: .5px;
	color: #999;
	background-color: transparent;
	border: none;
	outline: none;
	transform: translateX(0);
	transition: all .3s ease-out;
}

.form-wrapper.is-active .switcher-login {
	color: #fff;
  background : 'purple';
	transform: translateX(90px);
}

.form-wrapper.is-active .switcher-signup {
	color: #fff;
	transform: translateX(-90px);
}

.underline {
	position: absolute;
	bottom: -5px;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	width: 100%;
	height: 2px;
}

.underline::before {
	content: '';
	position: absolute;
	top: 0;
	left: inherit;
	display: block;
	width: inherit;
	height: inherit;
	background-color: currentColor;
	transition: transform .2s ease-out;
}

.switcher-login .underline::before {
	transform: translateX(101%);
}

.switcher-signup .underline::before {
	transform: translateX(-101%);
}

.form-wrapper.is-active .underline::before {
	transform: translateX(0);
}

.form {
	overflow: hidden;
	min-width: 260px;
	margin-top: 50px;
	padding: 30px 25px;
  border-radius: 5px;
	transform-origin: top;
}

.form-login {
	animation: hideLogin .3s ease-out forwards;
}

.form-wrapper.is-active .form-login {
	animation: showLogin .3s ease-in forwards;
}

@keyframes showLogin {
	0% {
		background: #d7e7f1;
		transform: translate(40%, 10px);
	}
	50% {
		transform: translate(0, 0);
	}
	100% {
		background-color: #fff;
		transform: translate(35%, -20px);
	}
}

@keyframes hideLogin {
	0% {
		background-color: #fff;
		transform: translate(35%, -20px);
	}
	50% {
		transform: translate(0, 0);
	}
	100% {
		background: #d7e7f1;
		transform: translate(40%, 10px);
	}
}

.form-signup {
	animation: hideSignup .3s ease-out forwards;
}

.form-wrapper.is-active .form-signup {
	animation: showSignup .3s ease-in forwards;
}

@keyframes showSignup {
	0% {
		background: #d7e7f1;
		transform: translate(-40%, 10px) scaleY(.8);
	}
	50% {
		transform: translate(0, 0) scaleY(.8);
	}
	100% {
		background-color: #fff;
		transform: translate(-35%, -20px) scaleY(1);
	}
}

@keyframes hideSignup {
	0% {
		background-color: #fff;
		transform: translate(-35%, -20px) scaleY(1);
	}
	50% {
		transform: translate(0, 0) scaleY(.8);
	}
	100% {
		background: #d7e7f1;
		transform: translate(-40%, 10px) scaleY(.8);
	}
}

.form fieldset {
	position: relative;
	opacity: 0;
	margin: 0;
	padding: 0;
	border: 0;
	transition: all .3s ease-out;
}

.form-login fieldset {
	transform: translateX(-50%);
}

.form-signup fieldset {
	transform: translateX(50%);
}

.form-wrapper.is-active fieldset {
	opacity: 1;
	transform: translateX(0);
	transition: opacity .4s ease-in, transform .35s ease-in;
}

.form legend {
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
}

.input-block {
	margin-bottom: 20px;
}

.input-block label {
	font-size: 14px;
  color: #a1b4b4;
}

.input-block input {
	display: block;
	width: 100%;
	margin-top: 8px;
	padding-right: 15px;
	padding-left: 15px;
	font-size: 16px;
	line-height: 40px;
	color: #3b4465;
  background: #eef9fe;
  border: 1px solid #cddbef;
  border-radius: 2px;
}

.form [type='submit'] {
	opacity: 0;
	display: block;
	min-width: 120px;
	margin: 30px auto 10px;
	font-size: 18px;
	line-height: 40px;
	border-radius: 25px;
	border: none;
	transition: all .3s ease-out;
}

.form-wrapper.is-active .form [type='submit'] {
	opacity: 1;
	transform: translateX(0);
	transition: all .4s ease-in;
}

.btn-login {
	color: #fbfdff;
	background: red;
  box-shadow: inset 0 0 0 2px #a7e245;
	transform: translateX(-30%);
}

.btn-signup {
	color: #a7e245;
	background: #fbfdff;
	box-shadow: inset 0 0 0 2px #a7e245;
	transform: translateX(30%);
}

/* For the Loading Dialog */

.rotating {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* CSS styles for the dashboard */


.logo_animate {
	filter : grayscale(1);
	transform : scale(1);
	transition : all 1s;

	&:hover {
		/* animation: spin 2s linear infinite; */
		transition : all 1s;
		filter : grayscale(0);
		transform : scale(1.1);
		cursor : pointer;
	}
}

.slider_dashboard {
	transition : all 4s;
	transform : scale(0.8);
	filter : grayscale(0.8);

	&:hover {
		filter : grayscale(0);

		cursor : pointer;

		transition : all 3s;
		transform : scale(1);
		filter : grayscale(0);
	}
}

.btn_dashboard {
	filter : grayscale(1);
	transform : scale(1);
	transition : all 1s;

	&:hover {
		/* animation: spin 2s linear infinite; */
		transition : all 1s;
		filter : grayscale(0);
		transform : scale(1.1);
		box-shadow: 10px 10px 10px rgba(0,255,0,0.2);
		cursor : pointer;
	}
}

.btn_cur_project {
	filter : grayscale(1);
	transform : scale(1);
	transition : all 1s;

	&:hover {
		/* animation: spin 2s linear infinite; */
		transition : all 1s;
		filter : grayscale(0);
		transform : scale(1.1);
		box-shadow: 10px 10px 10px rgba(255,0,255,0.2);
		cursor : pointer;
	}
}

/* BREADCRUMB ACTIVE*/

.bread-active {
	user-select : none;
	&:hover {
		text-decoration : underline;
		text-underline-offset : 10px;
		cursor : pointer;
		color : rgba(0,255,0,0.8);
		fontWeight : '800';
		user-select : none;
	}
}

.revert-dialog {
	/* filter : invert(1); */
}


.btn_bot {

	object-fit: 'contain';
	width: '200px';
	height: '100px';
	border-radius: 20px;
	border: '3px solid gray';
	cursor: 'pointer';

	filter : grayscale(0);
	transform : scale(1);
	transition : all 1s;

	&:hover {
		/* animation: spin 2s linear infinite; */
		transition : all 1s;
		filter : grayscale(0);
		transform : scale(1.1);
		box-shadow: 10px 10px 10px rgba(0,255,0,0.2);
		cursor : pointer;
	}
}

.btn_dashboard {
	filter : grayscale(1);
	transform : scale(1);
	transition : all 1s;

	&:hover {
		/* animation: spin 2s linear infinite; */
		transition : all 1s;
		filter : grayscale(0);
		transform : scale(1.1);
		box-shadow: 10px 10px 10px rgba(0,255,0,0.2);
		cursor : pointer;
	}
}

.btn_cur_project {
	filter : grayscale(1);
	transform : scale(1);
	transition : all 1s;

	&:hover {
		/* animation: spin 2s linear infinite; */
		transition : all 1s;
		filter : grayscale(0);
		transform : scale(1.1);
		box-shadow: 10px 10px 10px rgba(255,0,255,0.2);
		cursor : pointer;
	}
}



/* The panel in the market maker */

.market-maker-panel {
	filter : grayscale(1);
	transform : scale(1);
	transition : all 1s;
	.panel-text {
		color : gray;
	}
}

.market-maker-panel:hover {
	filter : grayscale(0);
	transform : scale(1.02);
	transition : all 1s;
	.panel-text {
		color : white;
	}
}


/* For the slider css */ 

/* slider-styles.css */
/* slider-styles.css */
/* slider-styles.css */

.react-slider {
	position: relative;
	width: 100%; /* Full width of the container */
	height: 30px; /* Height of the slider container */
  }
  
  .track {
	background: #ddd; /* Light grey background for the track */
	height: 4px; /* Thickness of the track */
	border-radius: 2px; /* Rounded corners for the track */
	position: absolute;
	top: 50%; /* Center vertically */
	transform: translateY(-50%); /* Adjust for vertical centering */
  }
  
  .track-0 {
	background: #4a90e2; /* Color of the active track */
	position: absolute;
	left: 0; /* Start from the left edge */
  }
  
  .track-1 {
	background: #1f1f1f;
	/* background: blue; */
	position: absolute;
	left : 0; /* Start from the left edge */
  }

    
  .track-red {
	background: red;
	position: absolute;
	left : 0; /* Start from the left edge */
  }
  
  
  .thumb {
	width: 25px; /* Width of the thumb */
	height: 25px; /* Height of the thumb */
	background: #4a90e2; /* Color of the thumb */
	border-radius: 50%; /* Make the thumb circular */
	cursor: pointer; /* Pointer cursor on hover */
	border: 2px solid #fff; /* Optional: border around the thumb */
	position: absolute; /* Position it relative to the container */
	top: 50%; /* Center vertically */
	transform: translate(-50%, -50%); /* Adjust for vertical centering */
  }
  