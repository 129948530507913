@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #020B1A;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* For Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 6px;
    /* width of the entire scrollbar */
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #00000000;
    /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background-color: #ffffff12;
    /* color of the scroll thumb */
    border-radius: 16px;
    /* roundness of the scroll thumb */
    border: 3px solid #00000000;
    /* creates padding around scroll thumb */
}

/* For Firefox */
* {
    scrollbar-width: thin;
    /* "auto" or "thin" */
    scrollbar-color: #ffffff12 #00000000;
    /* thumb and track color */
}

/* Hover effect for webkit scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff12;
}

input[type="checkbox"]:checked {
    opacity: 1 !important;
    border: none;
}